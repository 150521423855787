.home__page{
    background: white;
}
.home__page:after{
    content: '';
    background-image: url(/assets/images/backImage.svg);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
}
.header-translucent .header-background{
    backdrop-filter: none;
}
.header-translucent ion-toolbar{
    background: transparent;
    --background: transparent;
    --border-width: 0 !important;
}
.header-translucent::after, .home__footer::before{
    display: none;
}
.home__page ion-content {
    background: transparent;
    z-index: 9;
    --background: transparent;
}
.home__page ion-content {
    background: transparent;
    z-index: 9;
    --background: transparent;
}
.footer__action__btn {
    width: 140px;
    height: 140px;
    background: #000000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 100%;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: white;
    cursor: pointer;
}
.home__footer ion-toolbar{
    --border-width: 0 !important;
}